import React from 'react';
import { withSentryErrorBoundary as withSentryErrorBoundaryHOC } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { SENTRY_COMPONENT_SCRIPT_DSN } from '../constants/sentry-viewer-dsn';
import { getStaticsVersionFromUrl } from '../services/statics-version';

const SENTRY_CONFIG_APP = {
  dsn: SENTRY_COMPONENT_SCRIPT_DSN,
  config: {
    environment: `ooi-forum-app-${process.env.NODE_ENV}`,
  },
};
const SENTRY_CONFIG_RPW = {
  dsn: SENTRY_COMPONENT_SCRIPT_DSN,
  config: {
    environment: `ooi-recent-posts-widget-${process.env.NODE_ENV}`,
  },
};

export const withSentryErrorBoundaryApp = (ComponentToWrap, ErrorStateComponent) => {
  const SentryHOC = withSentryErrorBoundaryHOC(
    ComponentToWrap,
    SENTRY_CONFIG_APP,
    ErrorStateComponent,
  );

  return class ErrorBoundary extends React.PureComponent {
    setArtifactVersion = () => {
      if (!SENTRY_CONFIG_APP.config.release) {
        const version = getStaticsVersionFromUrl(this.props.cssBaseUrl);
        SENTRY_CONFIG_APP.config.release = version;
      }
    };
    render() {
      this.setArtifactVersion();
      return <SentryHOC {...this.props}>{this.props.children}</SentryHOC>;
    }
  };
};

export const withSentryErrorBoundaryRPW = (ComponentToWrap, ErrorStateComponent) => {
  const SentryHOC = withSentryErrorBoundaryHOC(
    ComponentToWrap,
    SENTRY_CONFIG_RPW,
    ErrorStateComponent,
  );

  return class ErrorBoundary extends React.PureComponent {
    setArtifactVersion = () => {
      if (!SENTRY_CONFIG_RPW.config.release) {
        const version = getStaticsVersionFromUrl(this.props.cssBaseUrl);
        SENTRY_CONFIG_RPW.config.release = version;
      }
    };
    render() {
      this.setArtifactVersion();
      return <SentryHOC {...this.props}>{this.props.children}</SentryHOC>;
    }
  };
};
